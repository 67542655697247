import {Modal, Platform, Pressable, View} from "react-native"
import {Portal, PortalHost} from "conjointment"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digs} from "diggerize"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useEventListener from "@kaspernj/api-maker/build/use-event-listener"
import {useModals} from "components/modal"

export default memo(shapeComponent(class ComponentsLayoutOverlay extends ShapeComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onRequestClose: PropTypes.func
  }

  setup() {
    this.modals = useModals()

    if (Platform.OS == "web") {
      useEventListener(window, "keyup", this.tt.onWindowKeyUp)
    }
  }

  render() {
    const {children, className, color, dataSet: givenDataSet, onRequestClose, ...restProps} = this.props
    const component = classNames("layout--overlay", givenDataSet?.component)
    const dataSet = Object.assign({}, givenDataSet, {class: className, component})
    const amountOfModalsShown = Object.keys(this.tt.modals.modals).length

    // Only way to get the overlay on top of a modal
    if (amountOfModalsShown > 0) {
      return this.renderWithReactNativeModal({dataSet, restProps})
    }

    // Only way to get the menu button on top of an overlay (like the Unicorn blocker)
    return (
      <Portal name="overlay">
        {this.modalContent({dataSet, restProps})}
      </Portal>
    )
  }

  renderWithReactNativeModal({dataSet, restProps}) {
    return (
      <Modal onBackdropPress={this.props.onRequestClose} onRequestClose={this.props.onRequestClose} transparent>
        <PortalHost>
          {this.modalContent({dataSet, restProps})}
        </PortalHost>
      </Modal>
    )
  }

  modalContent({dataSet, restProps}) {
    const {children, color, onRequestClose} = this.props
    let background

    if (color == "light") {
      background = "rgba(255, 255, 255, .85)"
    } else {
      background = "rgba(0, 0, 0, .85)"
    }

    return (
      <View style={{position: "fixed", top: 0, left: 0, zIndex: 997, width: "100%", height: "100vh", background, color: "#fff"}}>
        <View dataSet={dataSet} style={{width: "100%", height: "100%"}} {...restProps}>
          <View style={{alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
            {children}
          </View>
          {onRequestClose &&
            <Pressable
              dataSet={{class: "close-overlay-button"}}
              onPress={onRequestClose}
              style={{
                position: "absolute",
                top: 30,
                right: 30
              }}
            >
              <FontAwesomeIcon
                name="remove"
                style={{
                  color: color == "light" ? "#000" : "#fff",
                  fontSize: 36
                }}
              />
            </Pressable>
          }
        </View>
      </View>
    )
  }

  onWindowKeyUp = (e) => {
    const {onRequestClose} = this.props
    const {keyCode} = digs(e, "keyCode")

    if (keyCode == 27 && onRequestClose) { // Escape key
      e.preventDefault()
      onRequestClose()
    }
  }
}))
