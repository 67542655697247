import React, {createContext, useContext, useMemo} from "react"
import Route, {RequireComponentContext, RouteContext} from "@kaspernj/api-maker/build/router/route"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history.js"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Router from "@kaspernj/api-maker/build/router"
import Routes from "shared/routes"
import Switch from "@kaspernj/api-maker/build/router/switch"
import usePath from "on-location-changed/build/use-path"

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const requireComponent = ({routeDefinition}) => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}-route`)
const requireComponentLazy = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}-route`))
const routeDefinitions = require("shared/route-definitions.json")

const WoofRouterContext = createContext(null)
const useWoofRouter = () => useContext(WoofRouterContext)

export {useWoofRouter, WoofRouterContext}

export default memo(shapeComponent(class LocalesRouter extends ShapeComponent {
  setup() {
    this.path = usePath()
    this.useStates({
      firstTime: true,
      lastUpdate: new Date(),
      useOldRouter: false
    })
    this.woofRouter = useMemo(() => ({
      setPageNotFound: (value) => {
        if (value && !this.s.useOldRouter) {
          this.setState({lastUpdate: new Date(), useOldRouter: true})
        }
      }
    }))

    useMemo(() => {
      if (this.s.firstTime) {
        this.state.firstTime = false
      } else {
        this.state.useOldRouter = false
      }
    }, [this.path])
  }

  render() {
    const {useOldRouter} = this.s

    let pathParts = this.path.split("/")

    pathParts = pathParts.slice(1, pathParts.length)

    return (
      <>
        <RequireComponentContext.Provider value={requireComponent}>
          {useOldRouter === false &&
            <WoofRouterContext.Provider value={this.tt.woofRouter}>
              <RouteContext.Provider value={pathParts.join("/")}>
                <Switch name="root-router">
                  {Locales.availableLocales().map((locale) =>
                    <Route componentPath="locale" includeInPath={false} key={locale} path={locale} />
                  )}
                  <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
                </Switch>
              </RouteContext.Provider>
            </WoofRouterContext.Provider>
          }
          {useOldRouter === true &&
            <Router
              history={AppHistory}
              notFoundComponent={LayoutNotFoundPage}
              path={this.path}
              requireComponent={requireComponentLazy}
              routeDefinitions={routeDefinitions}
              routes={Routes}
            />
          }
        </RequireComponentContext.Provider>
      </>
    )
  }

  onFallbackMatch = () => this.tt.woofRouter.setPageNotFound(true)
}))
