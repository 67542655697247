import {PortalHost, PortalProvider} from "conjointment"
import {createRoot} from "react-dom/client"
import {Container as FlashNotifications} from "flash-notifications"
import {LoadingBlocker} from "components/layout/loading-blocker"
import ModelsResponseReader from "@kaspernj/api-maker/build/models-response-reader"
import React from "react"
import SessionStatusUpdater from "@kaspernj/api-maker/build/session-status-updater"
import WithLocationPath from "on-location-changed/build/with-location-path"
import WoofRouter from "shared/woof-router"

require("shared/devise")


if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

globalThis.sessionStatusUpdater = new SessionStatusUpdater()
globalThis.sessionStatusUpdater.startTimeout()

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <WithLocationPath>
      <PortalProvider>
        <PortalHost name="base" placement="below">
          <LoadingBlocker />
          <FlashNotifications />
          <WoofRouter />
        </PortalHost>
      </PortalProvider>
    </WithLocationPath>
  )
})
