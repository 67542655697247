import memo from "set-state-compare/src/memo"
import React from "react"
import {Text} from "react-native"

const UtilsText = memo(({style, ...restProps}) => {
  const actualStyle = Object.assign({}, {fontFamily: "Nunito"}, style)

  return <Text style={actualStyle} {...restProps} />
})

export default UtilsText
