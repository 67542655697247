import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
export default memo(shapeComponent(class ComponentsLayoutLoadingSpinner extends ShapeComponent {
  static propTypes = propTypesExact({
    color: PropTypes.string
  })

  render() {
    const {color} = this.props

    return (
      <div className="fa-3x" style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
        <i className="fas fa-spinner fa-spin" style={{color}} />
      </div>
    )
  }
}))
