import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import EventEmitter from "events"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import Overlay from "components/layout/overlay"
import React from "react"
import Text from "components/utils/text"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const state = {count: 0}
const eventEmitter = new EventEmitter()

const increaseLoading = (loadingId, label) => {
  state.count += 1
  eventEmitter.emit("loadingIncreased", loadingId, label)
}

const decreaseLoading = (loadingId) => {
  state.count -= 1
  eventEmitter.emit("loadingDecreased", loadingId)
}

const loading = async (label, callback) => {
  const loadingId = uuidv4()

  try {
    increaseLoading(loadingId, label)

    return await callback()
  } finally {
    decreaseLoading(loadingId)
  }
}

const LoadingBlocker = memo(shapeComponent(class ComponentsLayoutLoadingBlocker extends ShapeComponent {
  setup() {
    this.useStates({loadings: {}})

    useEventEmitter(eventEmitter, "loadingDecreased", this.tt.onLoadingDecreased)
    useEventEmitter(eventEmitter, "loadingIncreased", this.tt.onLoadingIncreased)
  }

  render() {
    const count = Object.keys(this.s.loadings).length

    if (count <= 0) return null

    return (
      <Overlay dataSet={{component: "layout--loading-blocker"}}>
        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <View style={{marginRight: "25px"}}>
            <LoadingSpinner />
          </View>
          <View>
            {Object.values(this.s.loadings).map((loading) =>
              <View key={loading.id}>
                <Text style={{color: "#fff"}}>{loading.label}</Text>
              </View>
            )}
          </View>
        </View>
      </Overlay>
    )
  }

  onLoadingDecreased = (loadingId) => {
    const newLoadings = Object.assign({}, this.s.loadings)
    const oldLength = Object.keys(newLoadings).length

    if (!(loadingId in newLoadings)) {
      throw new Error(`Loading ID ${loadingId} wasn't found in loadings: ${Object.keys(this.s.loadings).join(", ")}`)
    }

    delete newLoadings[loadingId]

    this.setState({loadings: newLoadings})

    const newLength = Object.keys(this.s.loadings).length

    if (newLength != (oldLength - 1)) {
      throw new Error("Loadings didnt decrease!")
    }
  }

  onLoadingIncreased = (loadingId, label) => {
    const newLoadings = Object.assign({}, this.s.loadings)

    newLoadings[loadingId] = {id: loadingId, label}

    this.setState({loadings: newLoadings})
  }
}))

export {decreaseLoading, increaseLoading, loading, LoadingBlocker}
