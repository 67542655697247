import CommandsPool from "@kaspernj/api-maker/build/commands-pool"
import getTimeZoneOffset from "shared/get-time-zone-offset"

const isDST = (date) => {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()

  return Math.max(jan, jul) !== date.getTimezoneOffset()
}

// Send time zone offset to backend
let offset = getTimeZoneOffset()
const expires = new Date()

if (isDST(expires)) {
  offset -= 3600
}

expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000))

document.cookie = `time_zone_offset=${offset}; expires=${expires.toUTCString()}`

CommandsPool.current().globalRequestData.time_zone_offset = offset
