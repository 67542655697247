import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"

export default memo(shapeComponent(class ComponentsCard extends ShapeComponent {
  static defaultProps = {
    childrenBottomRounded: true
  }

  static propTypes = {
    cardRef: PropTypes.object,
    childrenBottomRounded: PropTypes.bool.isRequired,
    childrenProps: PropTypes.object,
    className: PropTypes.string,
    footer: PropTypes.node,
    footerBackground: PropTypes.string,
    footerPadding: PropTypes.string,
    header: PropTypes.node,
    subHeader: PropTypes.node
  }

  render() {
    const {
      cardRef,
      children,
      childrenBottomRounded,
      childrenProps,
      className,
      footer,
      footerBackground,
      footerPadding,
      header,
      subHeader,
      ...restProps
    } = this.props
    const backgroundStyle = {}
    const footerStyle = {}

    if (footerBackground) backgroundStyle.background = footerBackground
    if (footerPadding) footerStyle.padding = footerPadding

    return (
      <div className={classNames("components--card", className)} ref={cardRef} style={backgroundStyle} {...restProps}>
        <div className="card-children" data-children-bottom-rounded={Boolean(!footer && childrenBottomRounded)} {...childrenProps}>
          {header &&
            <div className="card-header">
              {header}
            </div>
          }
          {subHeader &&
            <div className="card-sub-header">
              {subHeader}
            </div>
          }
          {children}
        </div>
        {footer &&
          <div className="card-footer" style={Object.assign(footerStyle, backgroundStyle)}>
            {footer}
          </div>
        }
      </div>
    )
  }
}))
